<template>
  <div class="partners-wrapper">
    <h3>Partneri</h3>

    <b-row
      v-for="(partner, index) in partners"
      :key="`partner-${index}`"
      align-v="center"
    >
      <b-col sm="4" xs="12" class="image">
        <a :href="url(partner)" target="_blank">
          <img :src="logo(partner)" />
        </a>
      </b-col>
      <b-col sm="8" xs="12" class="text">
        <p v-html="partner.attributes.description" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { get } from "lodash";
import { resolveCmsMediaUrl } from "/utils/helpers";

export default {
  props: {
    partners: {
      type: Array,
      required: true,
    },
  },

  computed: {
    logo() {
      return (partner) =>
        resolveCmsMediaUrl(
          get(partner, "attributes.logo.data.attributes.url")
        ) ||
        `https://via.placeholder.com/150/E2EDF3/FFFFFF?text=${partner.name}+Logo`;
    },

    url() {
      return (partner) => get(partner, "attributes.link");
    },
  },
};
</script>

<style lang="scss" scoped>
img {
  max-width: 100%;
}

.partners-wrapper {
  .row {
    padding: 3.5rem 0;

    &:not(:last-of-type) {
      border-bottom: 1px solid var(--a-color-blue-border);
    }
  }
}

.image,
.text {
  padding: 0 5%;
}

@media only screen and (max-width: 575px) {
  .partners-wrapper {
    .row {
      padding: 2rem 0;

      > *:not(:last-of-type) {
        margin-bottom: 1rem;
      }
    }
  }
}
</style>
